<template>
  <div>
<!--    <div class="row d-flex justify-content-center">
      <div class="col-12 col-lg-2">
        <label>Data początkowa</label>
        <date-picker @input="loadProvisionSum" v-model="marketplaceProvision.filtering.startDate" lang="pl-PL" />
      </div>

      <div class="col-12 col-lg-2">
        <label>Data końcowa</label>
        <date-picker @input="loadProvisionSum" v-model="marketplaceProvision.filtering.endDate" lang="pl-PL" />
      </div>
    </div>-->

<!--    <div class="text-center py-4">
      <p class="text-warning" v-if="marketplaceProvision.loading">Sumowanie prowizji...</p>
      <p v-else class="text-danger">Prowizja Marketplace: {{ marketplaceProvision.sum }} PLN</p>
    </div>-->

    <ecat-table
        :fields="getFields()"
        :items="loadOrders"
        :pagination-top="true"
        :table="table"
    >
      <template v-slot:products="{ item }">
        <div :set="orderProducts = getOrderProducts(item.id)">
          <template v-if="orderProducts && orderProducts.length > 0">
            <span>{{ orderProducts[0].name }}</span>
            <br>
            <a v-if="orderProducts.length - 1 !== 0" class="clickable-element text-primary"
               @click="openShowProductsModal(item)">+ Pokaż wszystkie ({{ orderProducts.length }})</a>
          </template>
          <template v-else>
            <span>Brak produktów w zamówieniu</span>
          </template>
        </div>
      </template>

      <template v-slot:status="{ item }">
        <span>{{ $t('core-orders.order.status.' + item.status) }}</span>
      </template>

      <template v-slot:paymentStatus="{ item }">
        <span :class="paymentStatusColor(item.sbzPaymentStatus)"
              class="badge">{{ paymentStatusText(item.sbzPaymentStatus) }}</span>
      </template>

      <template v-slot:expectedProfit="{ item }">
        <span v-if="item.status === 'ORDER_PLACED' || item.status === 'ORDER_IN_PROGRESS'">
            {{ priceHelper.format(item.operatorProfit, getMarketCurrency(item)) }}
          </span>
        <span v-else>
          {{ priceHelper.format(0, getMarketCurrency(item)) }}
          </span>
      </template>

      <template v-slot:profit="{ item }">
          <span v-if="item.status === 'ORDER_COMPLETED'">
            {{ priceHelper.format(item.operatorProfit, getMarketCurrency(item)) }}
          </span>
        <span v-else>
          {{ priceHelper.format(0, getMarketCurrency(item)) }}
        </span>
      </template>

      <template v-slot:totalProductsCostTaxExcluded="{ item }">
        <span>{{ priceHelper.format(item.totalProductsCostTaxExcluded, getMarketCurrency(item)) }}</span>
      </template>

      <template v-slot:shippingCostTaxIncluded="{ item }">
        <span>{{ priceHelper.format(item.shippingCostTaxIncluded, getMarketCurrency(item)) }}</span>
      </template>

      <template v-slot:realShippingCostTaxIncluded="{ item }">
        <span>{{ priceHelper.format(item.realShippingCostTaxIncluded, getMarketCurrency(item)) }}</span>
      </template>

      <template v-slot:marketplaceProvision="{ item }">
        <span>{{ priceHelper.format(item.allegroProvision, getMarketCurrency(item)) }}</span>
      </template>

      <template v-slot:shippingCostDifferenceTaxExcluded="{ item }">
        <span>{{ priceHelper.format(item.shippingCostDifferenceTaxExcluded, getMarketCurrency(item)) }}</span>
      </template>

      <template v-slot:email="{ item }">
        <span v-if="getCustomer(item.customerId)">{{ getCustomer(item.customerId).email }}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:firstName="{ item }">
        <span v-if="getCustomerDetails(item.customerId)">{{ getCustomerDetails(item.customerId).firstName }}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:lastName="{ item }">
        <span v-if="getCustomerDetails(item.customerId)">{{ getCustomerDetails(item.customerId).lastName }}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:action="{ item }">
        <div class="button-items">
          <ecat-button v-if="forUserOnly && item.allegroAccountType === 'EXTERNAL' && item.shopType === 'ALLEGRO' && item.sbzPaymentStatus === 'WAITING'" :callback="() => paid(item)" variant="success btn-sm" message="Opłać" />
          <a :href="`${linkSledzenia}?id=${item.id}`" class="btn btn-sm btn-primary" target="_blank">Link śledzenia</a>
        </div>
      </template>
    </ecat-table>

    <b-modal
        v-if="modals.showProductsInOrder.order"
        v-model="modals.showProductsInOrder.visibleModal"
        :scrollable="true"
        hide-footer
        title="Produkty w zamówieniu"
        title-class="font-18"
        @esc="hideShowProductsModal"
        @hide="hideShowProductsModal">
      <form>
        <div>
          <template v-if="getOrderProducts(modals.showProductsInOrder.order.id)">
            <ul>
              <li v-for="(orderProduct, index) in getOrderProducts(modals.showProductsInOrder.order.id)" :key="index">
                {{ orderProduct.name }}
              </li>
            </ul>
          </template>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {productsHelper} from "@/helpers/products-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {priceHelper} from "@/helpers/price-helper";
//import DatePicker from "vue2-datepicker";

export default {
  props: {
    shopId: {
      type: String,
      default: null,
    },

    dashboard: {
      type: Boolean,
      default: false
    },

    forUserOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
   // DatePicker
  },

  computed: {
    priceHelper() {
      return priceHelper
    },

    linkSledzenia() {
      return process.env.VUE_APP_LINKSLEDZENIA_URL
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: this.dashboard ? 5 : 100,
        pageOptions: [5, 10, 25, 50, 100, 200],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        items: []
      },

      marketplaceProvision: {
        filtering: {
          startDate: "",
          endDate: ""
        },

        sum: 0,
        loading: false
      },

      modals: {
        showProductsInOrder: {
          visibleModal: false,
          order: null,
        }
      },

      shops: new Map(),
      shopOrderProducts: {},
      customers: new Map(),
      customersDetails: new Map()
    }
  },

  async created() {
    this.marketplaceProvision.filtering.startDate = new Date(2023, 0, 1, 0, 0, 0);
    this.marketplaceProvision.filtering.endDate = new Date();
    await this.loadProvisionSum()
  },

  methods: {

    async loadProvisionSum() {
      if (!this.marketplaceProvision.filtering.startDate || !this.marketplaceProvision.filtering.endDate) {
        this.marketplaceProvision.sum = 0
        return
      }

      this.marketplaceProvision.loading = true

      try {
        const {data} = await axios.get(`/shop-order/marketplace-provision-sum`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            startDate: this.marketplaceProvision.filtering.startDate.toISOString(),
            endDate: this.marketplaceProvision.filtering.endDate.toISOString()
          }
        });

        this.marketplaceProvision.sum = data.sum
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      this.marketplaceProvision.loading = false
    },

    async paid(shopOrder) {
      try {
        const json = {
          shopOrderId: shopOrder.id
        }

        const {data} = await axios.post(`/internal/order/shop-order/create-pay-for-order`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.$root.$emit('open-tpay-transaction-modal', data)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getMarketCurrency(shopOrder) {
      const shop = this.getShop(shopOrder.shopId)
      if (!shop) {
        return ""
      }

      return this.$store.getters["market/getMarketCurrency"](shop.marketId)
    },

    async loadShops() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let shopOrder of this.table.items) {
          ids.push(shopOrder.shopId);
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/shop/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shops = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getShop(shopId) {
      if (!this.shops || !shopId) {
        return null
      }

      return this.shops.get(shopId) || null
    },

    async loadOrders() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let url = this.shopId ? `/shop-order/pagination/by-shop/${this.shopId}` : "/shop-order/pagination/by-user"

        const {data} = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter
          }
        });

        this.table.items = data.shopOrders
        this.table.totalRows = data.count;
        this.table.rows = data.count;

        await this.loadShops()
        await this.loadShopOrderProducts()
        await this.loadCustomers()
        await this.loadCustomersDetails()

      } catch (error) {
        this.table.items = []
        this.table.totalRows = 0;
        this.table.rows = 0;
      }

      return this.table.items
    },

    async loadShopOrderProducts() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let order of this.table.items) {
          ids.push(order.id);
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProducts = {};
        for (const id of ids) {
          const orderProducts = []
          for (const orderProduct of data) {
            if (orderProduct.shopOrderId === id) {
              orderProducts.push(orderProduct)
            }
          }

          this.shopOrderProducts[id] = orderProducts
        }
      } catch (error) {
        console.log(error)
      }
    },

    getOrderProducts(id) {
      return this.shopOrderProducts[id]
    },

    async loadCustomers() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let order of this.table.items) {
          ids.push(order.customerId);
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/customer/fetch/multiple`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.customers = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getCustomer(id) {
      if (!this.customers || !id) {
        return null
      }

      return this.customers.get(id) || null
    },

    getCustomerDetails(id) {
      if (!this.customersDetails || !id) {
        return null
      }

      const customer = this.getCustomer(id)
      if (!customer || !customer.detailsId) {
        return null
      }

      return this.customersDetails.get(customer.detailsId) || null;
    },

    async loadCustomersDetails() {
      try {
        if (this.customers.size === 0) {
          return;
        }

        const ids = [];

        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of this.customers) {
          ids.push(value.detailsId);
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/customer/details/fetch/multiple`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.customersDetails = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    openShowProductsModal(order) {
      this.modals.showProductsInOrder.order = order;
      this.modals.showProductsInOrder.visibleModal = true
    },

    hideShowProductsModal() {
      this.modals.showProductsInOrder.visibleModal = false
      this.modals.showProductsInOrder.order = null;
    },

    paymentStatusText(paymentStatus) {
      switch (paymentStatus) {
        case 'WAITING':
          return 'Do weryfikacji'
        case 'SUCCESS':
          return 'Opłacona'
        case 'FAILED':
          return 'Nieopłacona'
      }

      return ""
    },

    paymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case "WAITING":
          return "badge-soft-warning"
        case "SUCCESS":
          return "badge-soft-success"
        case "FAILED":
          return "badge-soft-danger"
      }

      return ""
    },

    getFields() {
      let list = []
      if (this.dashboard) {
        list = [
          {
            key: "idAsNumber",
            label: "ID",
          },
          {
            key: "market",
            label: this.$t("orders.table.market"),
            formatter: (value, key, item) => {
              const shop = this.getShop(item.shopId)
              if (!shop) {
                return ""
              }

              return this.$t(this.$store.getters["market/getMarketName"](shop.marketId))
            }
          },
          {
            key: "shop", label: this.$t("orders.table.shop"), formatter: (value, key, item) => {
              const shop = this.getShop(item.shopId)
              if (!shop) {
                return ""
              }

              if (shop.domain) {
                return shop.domain
              }

              return shop.name
            }
          },
          {
            key: "allegroAccountName",
            label: this.$t("orders.table.allegro-account"),
            formatter: value => productsHelper.getOr(value, "-", value)
          },
          {key: "products", slot: true, label: this.$t("orders.table.products")},
          {key: "totalProductsCostTaxExcluded", slot: true, label: 'Przychód (netto)'},
          {key: "expectedProfit", slot: true, label: this.$t("orders.table.expected-profit")},
          {key: "profit", slot: true, label: this.$t("orders.table.profit") },
          {key: "shippingCostTaxIncluded", slot: true, label: "Koszt dostawy" },
          {key: "realShippingCostTaxIncluded", slot: true, label: "Rzeczywisty koszt dostawy" },
          {key: "marketplaceProvision", slot: true, label: "Prowizja Marketplace" },
          // {key: "shippingCostDifferenceTaxExcluded", slot: true, label: "Korekta transportu"},
          {key: "status", slot: true, label: this.$t("orders.table.status") },
          {
            key: "createdAt",
            label: this.$t("orders.table.order-date"),
            formatter: value => productsHelper.getOr(value, "-", dateUtil.asDateTimeNormal(value))
          }
        ]

        if (this.forUserOnly) {
          list.push({key: "action", slot: true, label: "Akcje"})
        }
      } else {
        list = [
          {
            key: "idAsNumber",
            label: "ID",
          },
          {
            key: "market",
            label: this.$t("orders.table.market"),
            formatter: (value, key, item) => {
              const shop = this.getShop(item.shopId)
              if (!shop) {
                return ""
              }

              return this.$t(this.$store.getters["market/getMarketName"](shop.marketId))
            }
          },
          {
            key: "shop", label: this.$t("orders.table.shop"), formatter: (value, key, item) => {
              const shop = this.getShop(item.shopId)
              if (!shop) {
                return ""
              }

              if (shop.domain) {
                return shop.domain
              }

              return shop.name
            }
          },
          {
            key: "allegroAccountName",
            label: this.$t("orders.table.allegro-account"),
            formatter: value => productsHelper.getOr(value, "-", value)
          },
          {key: "email", slot: true, label: "Email"},
          {key: "firstName", slot: true, label: this.$t("orders.table.first-name")},
          {key: "lastName", slot: true, label: this.$t("orders.table.last-name")},
          {
            key: "company",
            label: this.$t("orders.table.company"),
            formatter: value => productsHelper.getOr(value, "-", value)
          },
          {key: "products", slot: true, label: this.$t("orders.table.products")},
          {key: "totalProductsCostTaxExcluded", slot: true, label: 'Przychód (netto)'},
          {
            key: "totalProductsCostTaxIncluded",
            label: this.$t("orders.table.amount"),
            formatter: value => productsHelper.parsePrice(value)
          },
          {key: "expectedProfit", slot: true, label: this.$t("orders.table.expected-profit")},
          {key: "profit", slot: true, label: "Zysk"},
          {key: "paymentStatus", slot: true, label: "Status płatności"},
          {key: "shippingCostTaxIncluded", slot: true, label: "Koszt dostawy" },
          {key: "realShippingCostTaxIncluded", slot: true, label: "Rzeczywisty koszt dostawy" },
          {key: "marketplaceProvision", slot: true, label: "Prowizja Marketplace" },
          // {key: "shippingCostDifferenceTaxExcluded", slot: true, label: "Korekta transportu"},
          {key: "status", slot: true, label: "Status"},
          {
            key: "createdAt",
            label: this.$t("orders.table.order-date"),
            formatter: value => productsHelper.getOr(value, "-", dateUtil.asDateTimeNormal(value))
          },
          {key: "action", slot: true, label: "Akcje"}
        ]
      }

      return list
    }
  }

}
</script>