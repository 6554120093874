<template>
  <div class="mb-3" v-if="news" :class="`${isFirstNews ? 'col-xl-6' : 'col-xl-12'}`">
    <div class="newsletter d-flex rounded border-5" :class="`${isFirstNews ? 'flex-column' : 'flex-row' }`">
      <div class="newsletter-image">
        <img :src="news.defaultImage" class="w-100" alt="latest news image">
      </div>
      <div class="newsletter-box w-100 d-flex flex-column">
        <div class="newsletter-body mx-4 flex-grow-1 overflow-auto">
          <div class="newsletter-header py-3">
            {{ news.title }}
          </div>
          <div class="newsletter-content">
            <div v-html="news.content"/>
          </div>
        </div>
        <div class="newsletter-footer border-top py-3">
          <div :set="user = getNewsUser(news.author)" class="mx-4">
            <template v-if="user">
              <img :src="user.profilePictureUrl" alt="User Image" class="rounded-circle header-profile-user">
              przez <span class="overlay-title-highlight">{{ user.firstName }} {{ user.lastName }}</span> |
              {{ dateUtil.asDateTime(news.createdAt) }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {dateUtil} from "@/helpers/date-util";

export default {
  name: "news-render",

  computed: {
    dateUtil() {
      return dateUtil
    }
  },

  props: {
    news: {
      validator: function (value) {
        return Array.isArray(value) || value instanceof Object;
      },
      required: true
    },

    newsUser: {
      type: Map,
      required: true
    },

    isFirstNews: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getNewsUser(userId) {
      if (!this.newsUser) {
        return null;
      }

      return this.newsUser.get(userId) || null;
    }
  },

  mounted() {
    this.$root.$on("change-language");
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  }
}
</script>